<template>
  <div class="Enterprise">
  
    <MyMenu :menuname="'other'"></MyMenu>
    <!-- 头部 -->
    <Stepbar  :active="1"></Stepbar>
    <!-- 头部END -->

   
    <!-- 主要内容容器 -->
    <div class="Enterprise_content">
      <supplement :source="'placeOrder'"></supplement>
    </div>
    <!-- 主要内容容器END -->
  </div>
</template>
<script>
import Stepbar from './item/Stepbar.vue'
import supplement from './item/supplement.vue'

export default {
  name: 'Enterprise',
  components: { Stepbar,supplement},
  data() {
    return {
      
    }
  },
  created() {
   
  },
  computed: {
    
    
  },

  methods: {
    
  }
}
</script>
<style scoped>
@import '../../assets/css/index.css';
</style>

